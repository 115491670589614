import styled from 'styled-components';

import { FontWeights, minWidth, Text } from '@calm-web/design-system';

export const Wrapper = styled.div`
	padding-bottom: 12px;
	margin-bottom: 24px;

	${minWidth('tablet')} {
		margin-bottom: 16px;
	}

	${minWidth('desktop')} {
		margin-bottom: 24px;
	}

	${minWidth('desktopLarge')} {
		margin-bottom: 36px;
	}
`;

export const CarouselWrapper = styled.ul<{ $gap: number; $leftOffset: string }>`
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 0;
	display: flex;
	transform: translateX(${p => p.$leftOffset});
	transition: transform 0.2s ease-in-out;
	gap: ${p => p.$gap}px;
`;

export const CarouselSlide = styled.li<{ $height: string; $width: string }>`
	min-width: ${p => p.$width};
	min-height: ${p => p.$height};
`;

export const TitleContainer = styled.div<{ $showTitle: boolean }>`
	align-items: center;
	display: flex;
	justify-content: ${p => (p.$showTitle ? 'space-between' : 'right')};
`;

export const Title = styled(Text).attrs({
	el: 'h2',
	styleAs: 'h5',
	color: 'white',
	weight: FontWeights.Regular,
})`
	margin-top: 0;
	margin-bottom: 16px;
`;

export const RightAlignedContainer = styled.div`
	align-items: center;
	display: flex;
`;
