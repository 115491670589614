import { useIntl } from 'react-intl';
import { mutate } from 'swr';

import CardCarousel from '@/components/app/cards/CardCarousel';
import CardGrid from '@/components/app/cards/CardGrid';
import TagsList from '@/components/app/cards/TagsList';
import PackClassLink from '@/components/app/ui/PackClassLink';
import Loader from '@/components/Loader';
import { HandoffScreenConfig } from '@/components/SimplifiedSignup/Handoff/types';
import UpsellBanner from '@/components/UpsellBanner';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import useLockedContentUpsell from '@/hooks/app/useLockedContentUpsell';
import { usePackItemAttributes } from '@/hooks/app/usePackItemAttributes';
import { useUpsellBannerContent } from '@/hooks/app/useUpsellBannerContent/useUpsellBannerContent';
import { useThemeState, useUserState } from '@/hooks/store';
import { useTimeOfDay } from '@/hooks/utils/useTimeOfDay';
import genericMessages from '@/messages/messages';
import { getGreetingHeader } from '@/utils/app/getGreeting';

import { usePacksContext } from '../../layout/PacksProvider';
import ParagraphPack from '../ParagraphPack';
import { PageSubtitle, PageTitle } from './styles';
import { Pack, PackCardItem, PacksListProps, TextItem } from './types';

const PacksList = ({
	packFeedName,
	packs,
	tags,
	activeTag,
	isLoading,
	showGreeting = false,
}: PacksListProps) => {
	const timeOfDay = useTimeOfDay();
	const { formatMessage } = useIntl();

	function displaySupportedPack(pack: Pack) {
		switch (pack.display_type) {
			case 'carousel':
			case 'banner':
			case 'banner-carousel':
				return <CardCarousel pack={pack} displayType={pack.display_type} packFeedName={packFeedName} />;
			case 'grid':
				return <CardGrid pack={pack} />;
			case 'link':
				// TODO: This should happen on the backend (or we should support narrator classes)
				return !pack.pack_class.includes('narrator') ? (
					<PackClassLink pack={pack} cta={pack.title} theme="large" />
				) : null;
			case 'carousel-landscape':
				// These are recommended collections, which we don't support yet
				return null;
			case 'quick-nav':
				// not supported on home yet
				return null;
			case 'paragraph': {
				const textItem = pack.items.find(item => item.type === 'text');
				if (!textItem) {
					return null;
				}
				const { text } = textItem as TextItem;
				if (!text) {
					return null;
				}
				return <ParagraphPack id={pack.id} text={text} />;
			}
			default:
				return null;
		}
	}

	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const { flowConfig } = useLockedContentUpsell();
	const { upsellCopy } = useUpsellBannerContent();

	const user = useUserState();
	const {
		currentPackItem,
		pageTitle: pageTitleFromPack,
		pageSubtitle: pageSubtitleFromPack,
	} = usePacksContext();

	const { handleUnlockedPackItemClick } = usePackItemAttributes({
		packItem: currentPackItem ?? ({} as PackCardItem),
	});

	const theme = useThemeState();
	const getPageTitle = (): string | null => {
		if (theme.webAppLayout === 'profile') {
			return formatMessage(genericMessages.profile);
		}

		if (showGreeting) return null;

		if (pageTitleFromPack) {
			return pageTitleFromPack;
		}

		return null;
	};

	const getPageSubtitle = (): string | null => {
		if (pageSubtitleFromPack) {
			return pageSubtitleFromPack;
		}

		return null;
	};

	const handoffCTA = (flowConfig?.screens?.handoff as HandoffScreenConfig)?.CTA;

	if (handoffCTA) {
		handoffCTA.onClick = async () => {
			await mutate([`packs/feeds/${packFeedName}`, packFeedName, user?.id]);
			await handleUnlockedPackItemClick?.();
			setIsModalOpen(false);
		};
	}

	return (
		<>
			{upsellCopy && <UpsellBanner />}
			{showGreeting && (
				<PageTitle>
					{formatMessage(getGreetingHeader(timeOfDay)) + (user?.name ? `, ${user.name}` : '')}
				</PageTitle>
			)}
			{getPageTitle() && <PageTitle>{getPageTitle()}</PageTitle>}
			{getPageSubtitle() && <PageSubtitle>{getPageSubtitle()}</PageSubtitle>}
			{isLoading ? (
				<Loader />
			) : (
				<>
					{tags && tags.length > 0 && (
						<TagsList tags={tags} activeTag={activeTag} packFeedName={packFeedName} />
					)}
					{packs.map(pack => (
						<div key={pack.id} data-testid="pack-container">
							{displaySupportedPack(pack)}
						</div>
					))}
				</>
			)}
		</>
	);
};

export default PacksList;
