import styled from 'styled-components';

import { fontSize, FontSizes, FontWeights, minWidth, Text } from '@calm-web/design-system';

export const PageTitle = styled(Text).attrs({
	color: 'white',
	size: FontSizes.xl,
	weight: FontWeights.Regular,
	el: 'h1',
	role: 'heading',
})`
	margin-bottom: 20px;
	${minWidth('mobileLarge')} {
		${fontSize(FontSizes['2xl'])}
	}
`;

export const PageSubtitle = styled(Text).attrs({
	color: 'white',
	size: FontSizes.base,
	weight: FontWeights.Light,
	el: 'h2',
	lineHeight: FontSizes.base,
})`
	margin-bottom: 40px;
	${minWidth('mobileLarge')} {
		${fontSize(FontSizes.base)}
	}
`;
